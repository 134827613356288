const languages: { [key: string]: string }  = {
  en: 'English',
  es: 'Español',
  it: 'Italiano',
  fr: 'Français',
  pt: 'Português',
  de: 'Deutsch',
  sl: 'Slovenščina',
  nl: 'Nederlands',
  fi: 'Suomalainen',
  sv: 'Svenska',
  pl: 'Polski',
  ro: 'Românesc',
  cs: 'Česky',
  ru: 'русский'
}

interface Language {
  iso     : string
  in_url  : boolean
  country : string
}

// check if uri contains a language iso2 code
const uri_lang = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1')

// gets current language data
const init_language = (default_lang: string): Language => {
  // language data
  let language: Language = {
    iso     : default_lang,
    in_url  : false,
    country : default_lang
  }

  // attempt to get locale from url
  if (uri_lang !== undefined && uri_lang !== '/' && languages[uri_lang] !== undefined) {
    language.iso = uri_lang
    language.in_url = true
    language.country = uri_lang
  }

  language.country = language.country === 'en' ? 'gb' : language.country

  return language
}

export { init_language, Language, languages }
